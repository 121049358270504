import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { getPrivateBalance, getUserData, userState } from '@/features';
import { getUserStakes, stakesState } from '@/features/stake';
import { StakingApiService } from '@/entities';
import { BtnVariant, Button, ContentBlock, Input, SvgSelector, Text } from '@/shared';
import s from './stake-form.module.scss';
const StakeFormItem = ({ daysDuration, isActive, percent, onClick }) => (_jsxs("li", { className: s.tariff, onClick: onClick, children: [_jsxs("div", { className: s.tariffInfo, children: [_jsxs("div", { className: s.tariffDays, children: [daysDuration, " days"] }), _jsxs("div", { className: s.tariffProcent, children: [percent, "% ARP"] })] }), _jsx("div", { className: `${isActive ? s.tariffStatusSelected : s.tariffStatus}`, children: isActive && _jsx(SvgSelector, { id: "checked" }) })] }));
export const StakeForm = () => {
    const [amount, setAmount] = useState('');
    const { userAvailableStakes } = useUnit(stakesState);
    const { user } = useUnit(userState);
    const rawAddress = useTonAddress();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    useEffect(() => {
        if (rawAddress) {
            getPrivateBalance(rawAddress);
        }
    }, [rawAddress]);
    const [tariffsData, setTarriffsData] = useState([]);
    const [activeTariff, setActiveTariff] = useState(1);
    const [isSending, setIsSending] = useState(false);
    useEffect(() => {
        if (userAvailableStakes) {
            const newData = userAvailableStakes.map((el) => ({
                daysDuration: el.daysDuration,
                percent: el.percent,
                id: el.id,
                isSelected: false,
            }));
            setTarriffsData(newData);
        }
    }, [userAvailableStakes]);
    const stakingApi = new StakingApiService();
    const handleStake = async () => {
        if (isSending)
            return;
        try {
            setIsSending(true);
            const res = await stakingApi.newStake(Number(amount), activeTariff);
            toast('Stake request created successfully', {
                type: 'success',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#29A468' },
                style: { background: '#29A468' },
            });
            getUserData({ shouldSetLoading: false });
            getUserStakes();
            setAmount('');
        }
        catch (error) {
            toast('Something went wrong', {
                type: 'error',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#E51D41' },
                style: { background: '#E51D41' },
            });
        }
        finally {
            setIsSending(false);
        }
    };
    return (_jsxs("div", { className: s.inner, children: [_jsx("h2", { className: s.title, children: "Stake Private" }), _jsx("p", { className: s.description, children: "Select the duration and PRIVATE amount you want to send to the stake" }), _jsx(ContentBlock, { className: s.tariffs, children: _jsx("ul", { className: s.tariffsList, children: tariffsData
                        ?.sort((a, b) => a.daysDuration - b.daysDuration)
                        ?.map((el, index) => (_jsx(StakeFormItem, { onClick: () => setActiveTariff(index + 1), isActive: activeTariff === index + 1, ...el }, v1()))) }) }), _jsx(Input, { value: amount, setValue: (e) => {
                    let inputValue = e.target.value.replace(/,/g, '.');
                    inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
                    inputValue = inputValue.replace(/[^0-9.]+/, '');
                    inputValue = inputValue.length === 0 ? '0' : inputValue;
                    const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
                    if (isValidInput) {
                        setAmount(inputValue);
                    }
                }, inputMode: "decimal", pattern: "[0-9]*[.,]?[0-9]*", placeholder: "amount of private", className: `${s.input} ${rawAddress && (Number(amount) > Number(user?.balance) ? s.inputError : '')}`, classNameInner: `${rawAddress && (Number(amount) > Number(user?.balance) ? s.inputError2 : '')}` }), _jsxs(Text, { className: s.bal, children: ["Balance:", ' ', (user?.balance ?? 0) % 1 === 0
                        ? user?.balance.toLocaleString('en', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                        : user?.balance.toLocaleString('en', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }), ' ', "PRIVATE"] }), _jsx("p", { className: s.bonusText, children: "Bonus: 50 PRIVATE to withdraw limit for every 1,000 PRIVATE in staking.\"" }), _jsx(Button, { variant: BtnVariant.green, className: s.btn, onClick: () => (!rawAddress ? tonConnectUI.openModal() : handleStake()), disabled: !rawAddress
                    ? false
                    : Number(amount) > Number(user?.balance) ||
                        amount === '0' ||
                        amount === '' ||
                        Number(amount) < 100, children: rawAddress
                    ? Number(amount) > Number(user?.balance)
                        ? 'Not enough balance'
                        : Number(amount) < 100
                            ? 'Minimum 100'
                            : 'Stake'
                    : 'connect' })] }));
};
