import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import s from './modal.module.scss';
export const Modal = ({ children, onClose, isOpen, className, buttonClass, isCloseButton = true, }) => {
    const modalRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && isClosing) {
                setIsClosing(true);
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, isClosing]);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);
    return (_jsx(AnimatePresence, { onExitComplete: onClose, children: isOpen && (_jsx(motion.div, { className: s.modalBackdrop, transition: {
                duration: 0.3,
                bounce: 0,
            }, children: _jsx(motion.div, { className: `${s.modalContent} ${className || ''}`, ref: modalRef, initial: { y: '100%' }, animate: { y: 0 }, exit: { y: '100%' }, transition: {
                    duration: 0.3,
                    bounce: 0,
                    type: 'spring',
                }, children: children }) })) }));
};
