/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
export class TonApiService {
    constructor() {
        this._url = 'https://tonapi.io/v2/';
        this._token = 'AFJOFCU7U3SD7DYAAAAFNHSJF3J4OKQQEXIMHCEQSXUQJAGVP227ZLZ3G3MQTBEU5P3JMRI';
    }
    async send(url, data) {
        const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`, { headers: { Authorization: `Bearer ${this._token}` } });
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getAllJettonsBalances(account_id, currencies) {
        const data = {};
        if (currencies !== undefined) {
            data.currencies = currencies;
        }
        const result = await this.send(`accounts/${account_id}/jettons`, data);
        return result;
    }
}
