import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useUnit } from 'effector-react';
import { v1 } from 'uuid';
import { referralsState } from '@/features';
import { ContentBlock, Title } from '@/shared';
import { RefItem } from '../refItem';
import s from './refList.module.scss';
export const RefList = () => {
    const { referralsList } = useUnit(referralsState);
    return (_jsxs("div", { className: s.inner, children: [_jsx(Title, { variant: "h2", children: "Referral list" }), _jsx(ContentBlock, { className: s.content, children: !referralsList || referralsList?.length === 0 ? (_jsx("div", { className: s.emptyRefs, children: "You haven't invited anyone yet" })) : (referralsList?.slice(0, 50).map(ref => _jsx(RefItem, { ...ref }, v1()))) })] }));
};
