import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ContentBlock, Title, Text, Button, BtnVariant, SvgSelector } from '@/shared';
import CHANCE_IMG from '@/shared/lib/images/home-page/game.png';
import s from './chance.module.scss';
export const Chance = () => {
    const navigate = useNavigate();
    return (_jsxs(ContentBlock, { className: s.inner, onClick: () => navigate('/gambling'), children: [_jsx(motion.img, { src: CHANCE_IMG, alt: "chance", animate: { y: [10, 0, 10] }, transition: {
                    duration: 4,
                    repeat: Infinity,
                    repeatType: 'reverse'
                } }), _jsxs("div", { className: s.innerContent, children: [_jsxs("div", { className: s.innerContentTop, children: [_jsx(Title, { variant: "h4", children: "chance game" }), _jsx(Button, { variant: BtnVariant.transparent, children: _jsx(SvgSelector, { id: "arrow-right" }) })] }), _jsx(Text, { children: "You choose your own probability of winning. Try your luck and earn PRIVATE tokens" })] })] }));
};
