// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lasn9vBr4E7UL_NNTKH7{margin-top:24px}.EzeAY_0cV0d8IU8ba7qI{font-size:16px;font-weight:800;text-transform:uppercase;color:#d9dddb;margin-bottom:16px}.irM55UfU4Q1OcIASZ99d{font-size:21px;font-weight:800;text-align:center;display:flex;align-items:center;justify-content:center;height:70px}`, "",{"version":3,"sources":["webpack://./src/widgets/withdraw/ui/withdrawHistory/withdrawHistory.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,wBAAA,CACA,aAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".inner {\n  margin-top: 24px;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: 800;\n  text-transform: uppercase;\n  color: #D9DDDB;\n  margin-bottom: 16px;\n}\n\n.emptyHistory {\n  font-size: 21px;\n  font-weight: 800;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `Lasn9vBr4E7UL_NNTKH7`,
	"title": `EzeAY_0cV0d8IU8ba7qI`,
	"emptyHistory": `irM55UfU4Q1OcIASZ99d`
};
export default ___CSS_LOADER_EXPORT___;
