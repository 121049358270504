import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { useUnit } from 'effector-react';
import { Title } from '@/shared/components/title';
import { DepositItem } from '../depositItem';
import { depositState } from '@/features/deposit/model';
import s from './depositHistory.module.scss';
export const DepositHistory = () => {
    const { depositHistory } = useUnit(depositState);
    return (_jsxs("div", { className: s.inner, children: [_jsx(Title, { variant: "h2", children: "Deposit History" }), _jsx("ul", { children: !depositHistory || depositHistory.length === 0 ? (_jsx("div", { className: s.emptyHistory, children: "You didn't make a deposit yet" })) : (depositHistory.map((el) => _jsx(DepositItem, { ...el }, v1()))) })] }));
};
