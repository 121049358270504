/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { createEvent, createStore, combine } from 'effector';
import { DepositApiService } from '@/entities';
export const setDepositHistory = createEvent();
export const setIsLoadingHistory = createEvent();
export const getDepositHistory = createEvent();
export const depositStore = createStore(null)
    .on(setDepositHistory, (_, payload) => payload);
export const isLoadingStore = createStore(false)
    .on(setIsLoadingHistory, (_, payload) => payload);
const depositApiService = new DepositApiService();
getDepositHistory.watch(async () => {
    try {
        setIsLoadingHistory(true);
        const res = await depositApiService.depositsHistory();
        setDepositHistory(res);
    }
    catch (err) {
        console.error('Error fetching deposit history:', err);
        setDepositHistory([]);
    }
    finally {
        setIsLoadingHistory(false);
    }
});
export const depositState = combine({ depositHistory: depositStore, depositHistoryLoading: isLoadingStore });
