import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable import/no-default-export */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-empty-pattern */
import { useRef } from 'react';
import { useUnit } from 'effector-react';
import { SvgSelector, Button, BtnVariant } from '@/shared';
import { userState } from '@/features';
import s from './balance.module.scss';
export const BalanceStats = ({}) => {
    const line2StartRef = useRef(null);
    const { user } = useUnit(userState);
    return (_jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.deposit, onClick: () => window.open('https://dedust.io/swap/DFC/EQA3BC27XNeinlQFvvQQipMEoTLupUshAkSVb0W129TH_OIf', '_blank'), children: "Buy PRIVATE" }), _jsxs("div", { className: s.balance, children: [_jsxs("div", { className: s.balanceInfo, children: [_jsx("p", { className: s.balanceTitle, children: "Balance" }), _jsx("p", { className: s.balanceValue, children: (user?.balance ?? 0) % 1 === 0
                                    ? user?.balance.toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                    : user?.balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })] }), _jsx(Button, { variant: BtnVariant.grey, link: "/balance", smallButton: true, children: _jsx(SvgSelector, { id: "plus" }) }), _jsxs("div", { ref: line2StartRef, className: s.infoLineContainer, children: [_jsxs("div", { className: s.infoBullet, children: [_jsx("div", { className: s.circle1 }), _jsx("div", { className: s.circle2 }), _jsx("div", { className: s.circle3 }), _jsx("div", { className: s.circle4 }), _jsx("div", { className: s.circle5 }), _jsx("div", { className: s.circle6 }), _jsx("div", { className: s.circle7 }), _jsx("div", { className: s.circle7 })] }), _jsx("div", { className: `${s.infoLineContainerAnimation} ${s.transform0}` })] })] })] }));
};
