import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import s from './chance-history-item.module.scss';
export const ChanceHistoryItem = ({ chance, isWin, amount, coef }) => {
    const winAmount = amount * coef - amount;
    return (_jsx("li", { className: s.item, children: _jsxs("div", { className: s.content, children: [_jsx("div", { className: s.contentTop, children: _jsxs("p", { className: `${s.title} ${isWin ? s.titleSuccess : s.titleFailed}`, children: [isWin ? 'WIN' : 'LOSS', _jsxs("span", { className: s.titleRate, children: ["(", chance, "%)"] })] }) }), _jsxs("p", { className: s.contentProfit, children: [isWin ? '+' : '-', isWin
                            ? winAmount.toLocaleString('en', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            : amount, ' ', "PRIVATE"] })] }) }));
};
