// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3T9ySKNXNP3XUnr7C1j{outline:none;border:0;height:58px;position:relative;max-width:100%;transition:all .3s ease 0s;width:100%;margin-bottom:24px}.F3T9ySKNXNP3XUnr7C1j::before{content:"";position:absolute;top:0;left:0;z-index:1;height:58px;width:100%;max-width:100%;transition:all .3s;clip-path:polygon(0 0, 100% 0, 100% 75%, 95% 100%, 0 100%, 0 25%);background:rgba(255,255,255,.1)}.LjwY3nLczvZoYnrcSPmr{position:relative;z-index:2;font-weight:800;font-size:16px;padding:19px 16px;text-transform:uppercase;background:rgba(0,0,0,0);width:100%;border:none}.LjwY3nLczvZoYnrcSPmr:focus{outline:none}`, "",{"version":3,"sources":["webpack://./src/shared/components/input/input.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,0BAAA,CACA,UAAA,CACA,kBAAA,CACA,8BACE,UAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,kBAAA,CACA,iEAAA,CACA,+BAAA,CAIJ,sBACE,iBAAA,CACA,SAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,wBAAA,CACA,UAAA,CACA,WAAA,CACA,4BACE,YAAA","sourcesContent":[".inner {\n  outline: none;\n  border: 0;\n  height: 58px;\n  position: relative;\n  max-width: 100%;\n  transition: all 0.3s ease 0s;\n  width: 100%;\n  margin-bottom: 24px;\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 1;\n    height: 58px;\n    width: 100%;\n    max-width: 100%;\n    transition: all 0.3s;\n    clip-path: polygon(0 0, 100% 0, 100% 75%, 95% 100%, 0 100%, 0 25%);\n    background: rgba(255, 255, 255, 0.10);  \n  }\n}\n\n.input {\n  position: relative;\n  z-index: 2;\n  font-weight: 800;\n  font-size: 16px;\n  padding: 19px 16px;\n  text-transform: uppercase;\n  background: transparent;\n  width: 100%;\n  border: none;\n  &:focus {\n    outline: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `F3T9ySKNXNP3XUnr7C1j`,
	"input": `LjwY3nLczvZoYnrcSPmr`
};
export default ___CSS_LOADER_EXPORT___;
