// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pz_iuxBsy9w4waTrwn74{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.5);display:flex;justify-content:center;align-items:center;z-index:50001}.a9wy0uCjf5G8oLtlF6xj{background-color:#111112;width:100%;overscroll-behavior:contain;max-width:100%;height:100%;position:fixed;bottom:0;z-index:30}`, "",{"version":3,"sources":["webpack://./src/shared/components/modal/modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,wBAAA,CACA,UAAA,CACA,2BAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".modalBackdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 50001;\n}\n\n.modalContent {\n  background-color: #111112;\n  width: 100%;\n  overscroll-behavior: contain;\n  max-width: 100%;\n  height: 100%;\n  position: fixed;\n  bottom: 0;\n  z-index: 30;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBackdrop": `pz_iuxBsy9w4waTrwn74`,
	"modalContent": `a9wy0uCjf5G8oLtlF6xj`
};
export default ___CSS_LOADER_EXPORT___;
