// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TzrbxyM8i8NB1bpBf4rB{margin-top:24px}.dGdEo0ekVntRbmTKpi4Q{font-size:16px;font-weight:800;text-transform:uppercase;color:#d9dddb;margin-bottom:16px}.HyQz0v2PWrd1TLQ7YPZZ{font-size:21px;font-weight:800;text-align:center;display:flex;align-items:center;justify-content:center;height:70px}`, "",{"version":3,"sources":["webpack://./src/widgets/game/ui/chance-history/chance-history.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,wBAAA,CACA,aAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".inner {\n  margin-top: 24px;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: 800;\n  text-transform: uppercase;\n  color: #D9DDDB;\n  margin-bottom: 16px;\n}\n\n.emptyHistory {\n  font-size: 21px;\n  font-weight: 800;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `TzrbxyM8i8NB1bpBf4rB`,
	"title": `dGdEo0ekVntRbmTKpi4Q`,
	"emptyHistory": `HyQz0v2PWrd1TLQ7YPZZ`
};
export default ___CSS_LOADER_EXPORT___;
