import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-default-export */
/* eslint-disable import/no-internal-modules */
/* eslint-disable import/no-extraneous-dependencies */
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Title, Text, SvgSelector, Button, BtnVariant, ContentBlock } from '@/shared';
import FARMING_IMG from '@/shared/lib/images/home-page/farming.png';
import s from './farming.module.scss';
export const Farming = () => {
    const navigate = useNavigate();
    return (_jsxs(ContentBlock, { className: s.inner, onClick: () => navigate('/staking'), children: [_jsxs("div", { className: s.innerTop, children: [_jsx(Title, { variant: "h3", children: "STAKING" }), _jsx(Button, { variant: BtnVariant.transparent, children: _jsx(SvgSelector, { id: "arrow-right" }) })] }), _jsxs("div", { className: s.innerBottom, children: [_jsx(Text, { children: "Participate in PRIVATE farming and earn up to 228% APR" }), _jsx(motion.img, { src: FARMING_IMG, alt: "private coin", animate: { y: [-10, 0, -10] }, transition: {
                            duration: 4,
                            repeat: Infinity,
                            repeatType: 'reverse'
                        } })] })] }));
};
