import { createEvent, createStore, combine } from 'effector';
import { GamblingApiService } from '@/entities';
export const setGamblingHistory = createEvent();
export const setIsLoadingGambling = createEvent();
export const setGamblingResults = createEvent();
export const getGamblingHistory = createEvent();
export const gamblingHistoryStore = createStore([])
    .on(setGamblingHistory, (_, payload) => payload);
export const isLoadingGambling = createStore(false)
    .on(setIsLoadingGambling, (_, payload) => payload);
export const gamblingResultsStore = createStore(null)
    .on(setGamblingResults, (_, payload) => payload);
const gamblingApiService = new GamblingApiService();
getGamblingHistory.watch(async () => {
    try {
        setIsLoadingGambling(true);
        const res = await gamblingApiService.gameHistory();
        setGamblingHistory(res);
    }
    catch (err) {
        console.error('Error fetching gambling history:', err);
        setGamblingHistory([]);
    }
    finally {
        setIsLoadingGambling(false);
    }
});
export const gamblingState = combine({
    gamblingHistory: gamblingHistoryStore,
    gamblingHistoryLoading: isLoadingGambling,
    gamblingResult: gamblingResultsStore
});
