// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tsfzQPrOxsr0PrdzrZ82{margin-top:24px}.tsfzQPrOxsr0PrdzrZ82 h2{font-size:18px;font-weight:800;margin-bottom:16px}.tsfzQPrOxsr0PrdzrZ82 .U3hOMe2niFl8AiFRDgTI{margin-top:21px;width:100%;min-height:90px;height:100%}.tsfzQPrOxsr0PrdzrZ82 .U3hOMe2niFl8AiFRDgTI::before{width:100%;min-height:90px;height:100%;clip-path:none}.ILvmnVUGn0TzjR2ukepS{font-size:20px;font-weight:800;text-align:center;display:flex;min-height:100px;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/widgets/reffs/ui/refList/refList.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,yBACE,cAAA,CACA,eAAA,CACA,kBAAA,CAEF,4CACE,eAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CACA,oDACI,UAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CAKR,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".inner {\n  margin-top: 24px;\n  h2 {\n    font-size: 18px;\n    font-weight: 800;\n    margin-bottom: 16px;\n  }\n  .content {\n    margin-top: 21px;\n    width: 100%;\n    min-height: 90px;\n    height: 100%;\n    &::before {\n        width: 100%;\n        min-height: 90px;\n        height: 100%;\n        clip-path: none;\n    }\n  }\n}\n\n.emptyRefs {\n  font-size: 20px;\n  font-weight: 800;\n  text-align: center;\n  display: flex;\n  min-height: 100px;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `tsfzQPrOxsr0PrdzrZ82`,
	"content": `U3hOMe2niFl8AiFRDgTI`,
	"emptyRefs": `ILvmnVUGn0TzjR2ukepS`
};
export default ___CSS_LOADER_EXPORT___;
