import axios from 'axios';
const apiUrl = 'https://starfish-app-i3bq6.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class GamblingApiService {
    constructor() {
        this._url = apiUrl;
        this._headers = headers;
    }
    handleError(error) {
        console.error(error);
        return undefined;
    }
    async makeGetRequest(url, data) {
        try {
            const config = { params: data, headers: this._headers };
            const res = await axios.get(`${this._url}${url}`, config);
            return res.data;
        }
        catch (error) {
            return this.handleError(error);
        }
    }
    async makePostRequest(url, data) {
        const res = await axios.post(`${this._url}${url}`, data, { headers: this._headers });
        return res.data;
    }
    async get(url, data) {
        return this.makeGetRequest(url, data);
    }
    async post(url, data) {
        return this.makePostRequest(url, data);
    }
    // СОЗДАТЬ ИГРУ
    createGame(chance, amount) {
        return this.post('game', { amount, chance });
    }
    // ПОЛУЧИТЬ ИСТОРИЮ ИГР
    async gameHistory() {
        return this.get('game/history', {});
    }
}
