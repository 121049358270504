/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createEvent, createStore, combine } from 'effector';
import { UserApiService } from '@/entities';
export const setTasksCompleted = createEvent();
export const setIsLoadingTask = createEvent();
export const getTasksStatus = createEvent();
export const getTasksStatusLite = createEvent();
export const tasksCompletedStore = createStore([false, false])
    .on(setTasksCompleted, (_, payload) => payload);
export const isLoadingStoreTask = createStore(false)
    .on(setIsLoadingTask, (_, payload) => payload);
const tasksService = new UserApiService();
getTasksStatus.watch(async () => {
    setIsLoadingTask(true);
    const res = await tasksService.checkUserSubscribes();
    setTasksCompleted(res);
    setIsLoadingTask(false);
});
getTasksStatusLite.watch(async () => {
    const res = await tasksService.checkUserSubscribes();
    setTasksCompleted(res);
});
export const tasksState = combine({ tasksCompleted: tasksCompletedStore, tasksLoading: isLoadingStoreTask });
