/* eslint-disable import/no-cycle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { createEvent, createStore, combine } from 'effector';
import { WithdrawService } from '@/entities';
export const setWithdrawHistory = createEvent();
export const setIsLoading = createEvent();
export const getWithdrawHistory = createEvent();
export const withdrawStore = createStore(null)
    .on(setWithdrawHistory, (_, payload) => payload);
export const isLoadingStoreWithdraw = createStore(false)
    .on(setIsLoading, (_, payload) => payload);
const withdrawService = new WithdrawService();
getWithdrawHistory.watch(async () => {
    try {
        setIsLoading(true);
        const res = await withdrawService.historyDeposits();
        setWithdrawHistory(res);
    }
    catch (err) {
        console.error('Error fetching friends:', err);
        setWithdrawHistory([]);
    }
    finally {
        setIsLoading(false);
    }
});
export const withdrawState = combine({ withdrawHistory: withdrawStore, withdrawHistoryLoading: isLoadingStoreWithdraw });
