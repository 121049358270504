import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import { useInitData } from '@tma.js/sdk-react';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, BtnVariant, ContentBlock, Text, Title, SvgSelector } from '@/shared';
import s from './referrals.module.scss';
export const Referrals = () => {
    const navigate = useNavigate();
    const telegramUser = useInitData();
    const handleCopy = () => {
        toast('Invite link has been copied', {
            type: 'success',
            autoClose: 1000,
            draggable: true,
            bodyStyle: { background: '#29A468' },
            style: { background: '#29A468' }
        });
        copy(`https://t.me/privateton_bot/start?startapp=ref_${telegramUser?.user?.id}`);
    };
    return (_jsxs(ContentBlock, { className: s.inner, children: [_jsxs("div", { className: s.innerTop, onClick: () => navigate('/referrals'), children: [_jsx(Title, { variant: "h2", children: "Referrals" }), _jsx(Button, { variant: BtnVariant.transparent, children: _jsx(SvgSelector, { id: "arrow-right" }) })] }), _jsx(Text, { className: s.innerDesc, onClick: () => navigate('/referrals'), children: "Invite your friends and get millions of dollars for every person you invite" }), _jsxs("div", { className: s.innerActions, children: [_jsxs(Button, { variant: BtnVariant.grey, className: s.link, onClick: () => toast('Invite link has been copied', {
                            type: 'success',
                            autoClose: 1000,
                            draggable: true,
                            bodyStyle: { background: '#29A468' },
                            style: { background: '#29A468' }
                        }), children: ["https://t.me/privateton_bot/start?startapp=ref_", telegramUser?.user?.id] }), _jsx(Button, { variant: BtnVariant.grey, smallButton: true, onClick: handleCopy, children: _jsx(SvgSelector, { id: "copy" }) })] })] }));
};
