import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import { useInitData } from '@tma.js/sdk-react';
import copy from 'copy-to-clipboard';
import { useUnit } from 'effector-react';
import { toast } from 'react-toastify';
import { referralsState } from '@/features';
import { Button, BtnVariant, ContentBlock, Text, Title, SvgSelector } from '@/shared';
import s from './refStats.module.scss';
export const RefStats = () => {
    const telegramUser = useInitData();
    const { referralsList } = useUnit(referralsState);
    const totalRefs = referralsList?.length ?? 0;
    const totalPrivateRefs = referralsList?.reduce((acc, ref) => (ref.isSubscribed !== false ? acc + 0 : acc), 0) ?? 0;
    const handleCopy = () => {
        toast('Invite link has been copied', {
            type: 'success',
            autoClose: 1000,
            draggable: true,
            bodyStyle: { background: '#29A468' },
            style: { background: '#29A468' }
        });
        copy(`https://t.me/privateton_bot/start?startapp=ref_${telegramUser?.user?.id}`);
    };
    return (_jsxs("div", { className: s.inner, children: [_jsx(Title, { variant: "h1", children: "Referral program" }), _jsxs(ContentBlock, { className: s.content, children: [_jsxs("div", { className: s.stats, children: [_jsxs("div", { className: s.stat, children: [_jsx("span", { children: totalRefs }), "REFERRAL"] }), _jsxs("div", { className: s.stat, children: [_jsx("span", { children: totalPrivateRefs }), "PRIVATE"] })] }), _jsxs("div", { className: s.contentActions, children: [_jsxs(Button, { variant: BtnVariant.grey, className: s.link, onClick: handleCopy, children: ["https://t.me/privateton_bot/start?startapp=ref_", telegramUser?.user?.id] }), _jsx(Button, { variant: BtnVariant.grey, smallButton: true, onClick: handleCopy, children: _jsx(SvgSelector, { id: "copy" }) })] })] }), _jsx(Text, { className: s.info, children: "Your referral must subscribe to all channels on the start screen for the bonus to be credited." })] }));
};
