import { createElement } from 'react';
import s from './title.module.scss';
export const Title = ({ children, variant }) => {
    let TitleComponent;
    switch (variant) {
        case 'h1':
            TitleComponent = 'h1';
            break;
        case 'h2':
            TitleComponent = 'h2';
            break;
        case 'h3':
            TitleComponent = 'h3';
            break;
        case 'h4':
            TitleComponent = 'h4';
            break;
        case 'h5':
            TitleComponent = 'h5';
            break;
        default:
            TitleComponent = 'h1';
    }
    return createElement(TitleComponent, { className: s.title }, children);
};
