// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fHwJ8DwgxDF4jUHZAA_j{font-size:18px;font-family:"Orbitron";font-weight:800;text-transform:uppercase;color:#d9dddb}`, "",{"version":3,"sources":["webpack://./src/shared/components/title/title.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,sBAAA,CACA,eAAA,CACA,wBAAA,CACA,aAAA","sourcesContent":[".title {\n  font-size: 18px;\n  font-family: 'Orbitron';\n  font-weight: 800;\n  text-transform: uppercase;\n  color: #D9DDDB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `fHwJ8DwgxDF4jUHZAA_j`
};
export default ___CSS_LOADER_EXPORT___;
