import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { ContentBlock, Title, Button, BtnVariant, Text, SvgSelector } from '@/shared';
import ALERT_IMG from '@/shared/lib/images/home-page/community.png';
import s from './community.module.scss';
export const Community = () => (_jsxs(ContentBlock, { className: s.inner, children: [_jsxs("div", { className: s.innerTop, children: [_jsxs(Title, { variant: "h5", children: ["secret chats ", _jsx("br", {}), " and channels"] }), _jsx(Button, { variant: BtnVariant.transparent, children: _jsx(SvgSelector, { id: "arrow-right" }) })] }), _jsxs("div", { className: s.innerContent, children: [_jsx(Text, { children: "Join private communities and be among the first to receive exclusive information" }), _jsx(motion.img, { src: ALERT_IMG, alt: "alert", animate: { y: [-10, 0, -10] }, transition: {
                        duration: 4,
                        repeat: Infinity,
                        repeatType: 'reverse'
                    } })] })] }));
