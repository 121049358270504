import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { useUnit } from 'effector-react';
import { WithdrawItem } from '../withdrawItem';
import { withdrawState } from '@/features';
import s from './withdrawHistory.module.scss';
export const WithdrawHistory = () => {
    const { withdrawHistory } = useUnit(withdrawState);
    return (_jsxs("div", { className: s.inner, children: [_jsx("h4", { className: s.title, children: "Withdraw History" }), _jsx("ul", { children: !withdrawHistory || withdrawHistory.length === 0 ? (_jsx("div", { className: s.emptyHistory, children: "You didn't make a deposit yet" })) : (withdrawHistory.map(el => _jsx(WithdrawItem, { ...el }, v1()))) })] }));
};
