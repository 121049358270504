// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZrXXh1Cpo2tF3C6L3MgP{outline:none;border:none;cursor:pointer;max-width:100%;font-size:16px;font-weight:800;text-transform:uppercase;position:relative;background:none;padding:19px 16px;transition:all .3s ease 0s}.ZrXXh1Cpo2tF3C6L3MgP .ufHkCi8jjZqsIkqeayzs{position:relative;z-index:2}.ZrXXh1Cpo2tF3C6L3MgP::before{content:"";position:absolute;top:0;left:0;display:block;z-index:1;background:#2e4339;transition:all .3s;clip-path:polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%, 0 10%)}`, "",{"version":3,"sources":["webpack://./src/shared/components/contentBlock/contentBlock.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,0BAAA,CACA,4CACE,iBAAA,CACA,SAAA,CAEF,8BACE,UAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,aAAA,CACA,SAAA,CACA,kBAAA,CACA,kBAAA,CACA,iEAAA","sourcesContent":[".inner {\n  outline: none;\n  border: none;\n  cursor: pointer;\n  max-width: 100%;\n  font-size: 16px;\n  font-weight: 800;\n  text-transform: uppercase;\n  position: relative;\n  background: none;\n  padding: 19px 16px;\n  transition: all 0.3s ease 0s;\n  .content {\n    position: relative;\n    z-index: 2;\n  }\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    display: block;\n    z-index: 1;\n    background: #2E4339;\n    transition: all 0.3s;\n    clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%, 0 10%);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `ZrXXh1Cpo2tF3C6L3MgP`,
	"content": `ufHkCi8jjZqsIkqeayzs`
};
export default ___CSS_LOADER_EXPORT___;
