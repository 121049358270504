import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import { useUnit } from 'effector-react';
import { toast } from 'react-toastify';
import { getUserStakes, stakesState } from '@/features/stake';
import { StakingApiService } from '@/entities';
import { BtnVariant, Button } from '@/shared';
import s from './stake-item.module.scss';
export const StakeItem = ({ id, amount, endAt, profit, createdAt, stakeId, status }) => {
    const stakingApi = new StakingApiService();
    const { userAvailableStakes } = useUnit(stakesState);
    const unstakeItem = async () => {
        try {
            await stakingApi.withdrawStake(id);
            toast('Unstake request sent', {
                type: 'success',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#00A86B' },
                style: { background: '#00A86B' }
            });
            getUserStakes();
        }
        catch (error) {
            toast(error.response.data.error, {
                type: 'error',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#E51D41' },
                style: { background: '#E51D41' }
            });
        }
    };
    const handleUnstake = () => {
        window.Telegram.WebApp.showConfirm('Are you sure?', (isConfirmed) => {
            if (isConfirmed) {
                unstakeItem();
            }
        });
    };
    const tariffData = userAvailableStakes?.find(el => el.id === stakeId);
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };
    const daysDurationInMillis = (tariffData?.daysDuration ?? 0) * 24 * 60 * 60 * 1000;
    const createdAtInMillis = Number(createdAt);
    const eventTimeInMillis = createdAtInMillis + daysDurationInMillis;
    const eventTime = new Date(eventTimeInMillis);
    const formattedDateAt = formatDate(eventTime);
    const currentDate = new Date();
    const isBeforeEvent = currentDate > eventTime;
    return (_jsx("li", { className: s.item, children: _jsxs("div", { className: s.content, children: [_jsxs("div", { className: s.id, children: ["Stake #", id] }), _jsxs("div", { className: s.detail, children: [_jsxs("div", { className: s.info, children: [_jsx("p", { className: s.infoLeft, children: "End date:" }), _jsx("p", { className: s.infoRight, children: formattedDateAt })] }), _jsxs("div", { className: s.info, children: [_jsx("p", { className: s.infoLeft, children: "APR:" }), _jsxs("p", { className: s.infoRight, children: [tariffData?.percent, "%"] })] }), _jsxs("div", { className: s.info, children: [_jsx("p", { className: s.infoLeft, children: "Total:" }), _jsxs("p", { className: s.infoRight, children: [amount.toLocaleString('en', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }), " private"] })] }), _jsxs("div", { className: s.info, children: [_jsx("p", { className: s.infoLeft, children: "Rewards:" }), _jsxs("p", { className: s.infoRight, style: { color: '#29A468' }, children: [Number(profit ?? 0).toFixed(2), " Private"] })] }), isBeforeEvent && status !== 'END' && (_jsx(Button, { className: s.btn, variant: BtnVariant.green, onClick: handleUnstake, children: "Unstake" }))] })] }) }));
};
