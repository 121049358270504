/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createEvent, createStore, combine } from 'effector';
import { StakingApiService } from '@/entities';
const stakingApi = new StakingApiService();
// ПОЛУЧИТЬ СТЕЙКИНГИ ЮЗЕРА
export const setUserStakes = createEvent();
export const setIsLoadingUserStakes = createEvent();
export const getUserStakes = createEvent();
export const stakesUserStore = createStore(null)
    .on(setUserStakes, (_, payload) => payload);
export const stakesUserLoading = createStore(false)
    .on(setIsLoadingUserStakes, (_, payload) => payload);
getUserStakes.watch(async () => {
    try {
        setIsLoadingUserStakes(true);
        const res = await stakingApi.stakeUser();
        setUserStakes(res);
    }
    catch (err) {
        console.error('Error fetching friends:', err);
        setUserStakes([]);
    }
    finally {
        setIsLoadingUserStakes(false);
    }
});
// ПОЛУЧИТЬ ДОСТУПНЫЕ СТЕЙКИНГИ
export const setAvailableStakes = createEvent();
export const getAvailableStakes = createEvent();
export const availableStakesStore = createStore(null)
    .on(setAvailableStakes, (_, payload) => payload);
getAvailableStakes.watch(async () => {
    try {
        const res = await stakingApi.availableStakes();
        if (res) {
            setAvailableStakes(res);
        }
    }
    catch (error) {
        console.error('Error fetching friends:', error);
        setAvailableStakes([]);
    }
});
export const stakesState = combine({
    userStakes: stakesUserStore,
    userStakesLoading: stakesUserLoading,
    userAvailableStakes: availableStakesStore
});
