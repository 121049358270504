import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { v1 } from 'uuid';
import { stakesState } from '@/features/stake';
import { StakeItem } from '../stake-item';
import s from './stake-list.module.scss';
export const StakeList = () => {
    const [userStakeList, setUserStakeList] = useState([]);
    const { userStakes } = useUnit(stakesState);
    useEffect(() => {
        if (userStakes) {
            const newData = userStakes.map(el => ({
                ...el,
                isActive: false
            }));
            setUserStakeList(newData);
        }
    }, [userStakes]);
    return (_jsxs("div", { className: s.list, children: [_jsx("h2", { className: s.listTitle, children: "your staking list" }), userStakeList.length >= 1 ? userStakeList?.filter(el => el.status !== 'END').map(el => (_jsx(StakeItem, { ...el }, v1()))) : (_jsx("p", { className: s.listEmpty, children: "Your stake list is empty" }))] }));
};
