import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { smlAddr } from '@/shared/utils/smlAddr';
import { Button, BtnVariant } from '@/shared/components/btn';
import { ContentBlock } from '@/shared/components/contentBlock';
import s from './navbar.module.scss';
export const Navbar = () => {
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const rawAddress = useTonAddress();
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const handleClickOutside = (event) => {
        if (showMenu &&
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showMenu]);
    const auth = !!localStorage.getItem('ton-connect-ui_wallet-info');
    return (_jsxs("div", { className: s.navbar, children: [_jsx(Link, { to: "/", className: s.logo, children: "Private" }), _jsx(Button, { ref: buttonRef, variant: BtnVariant.grey, onClick: () => (!rawAddress ? tonConnectUI.openModal() : setShowMenu(!showMenu)), children: auth ? smlAddr(rawAddress) : 'Connect' }), showMenu && (_jsx("div", { ref: menuRef, className: s.menu, children: _jsx(ContentBlock, { children: _jsx(Button, { className: s.disconnect, onClick: () => {
                            tonConnectUI.disconnect();
                            setShowMenu(false);
                        }, children: "Disconnect" }) }) }))] }));
};
