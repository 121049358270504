import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/order */
/* eslint-disable import/no-internal-modules */
/* eslint-disable import/no-extraneous-dependencies */
import { motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { SvgSelector } from '@/shared';
import BG from '../../lib/images/layout/bg.png';
import EYE from '../../lib/images/home-page/decor.svg';
import s from './mainLayout.module.scss';
export const MainLayout = ({ children }) => {
    const { pathname } = useLocation();
    const isIntroduction = useMemo(() => pathname === '/introduction', [pathname]);
    const isHomePage = useMemo(() => pathname === '/', [pathname]);
    // const isBalancePage = useMemo(() => pathname === '/balance', [ pathname ])
    const isGamblingPage = useMemo(() => pathname === '/gambling', [pathname]);
    const isStackingPage = useMemo(() => pathname === '/staking', [pathname]);
    const isSomethingWrongPage = useMemo(() => pathname === '/something-went-wrong', [pathname]);
    useEffect(() => {
        document.body.style.backgroundImage = `url(${BG})`;
        return () => {
            document.body.style.backgroundImage = '';
        };
    }, []);
    return (_jsxs("div", { className: s.wrapper, style: { overflow: isIntroduction || isGamblingPage ? 'hidden' : 'initial' }, children: [_jsxs("div", { className: s.content, style: { position: isIntroduction ? 'static' : 'relative' }, children: [!isIntroduction && !isSomethingWrongPage && !isStackingPage && _jsx(Navbar, {}), children] }), !isIntroduction && !isSomethingWrongPage && (_jsxs("a", { href: "https://mainsupport8.t.me/", className: s.support, children: ["Contact Support", _jsx(SvgSelector, { id: "arrow-right-small" })] })), isHomePage && (_jsxs("div", { className: s.decor, style: { top: isHomePage ? '50%' : '' }, children: [_jsx(EYE, {}), _jsx(motion.div, { className: s.decorI, animate: { opacity: [0.4, 0.3, 0.15, 0.3] }, transition: {
                            duration: 4,
                            repeat: Infinity,
                            repeatType: 'reverse',
                        } })] })), isGamblingPage && (_jsxs("div", { className: s.decor, style: { top: isGamblingPage ? '50%' : '' }, children: [_jsx(EYE, {}), _jsx(motion.div, { className: s.decorI, animate: { opacity: [0.4, 0.3, 0.15, 0.3] }, transition: {
                            duration: 4,
                            repeat: Infinity,
                            repeatType: 'reverse',
                        } })] })), !isIntroduction && !isHomePage && !isSomethingWrongPage && !isGamblingPage && (_jsx("div", { className: `${s.decor} ${s.decorT}`, children: _jsx("div", { className: s.decorB }) })), isSomethingWrongPage && (_jsx("div", { className: `${s.decor} ${s.decorOrange}`, children: _jsx("div", { className: `${s.decorB} ${s.decorO}` }) }))] }));
};
