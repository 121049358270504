import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { BtnVariant, Button, Input, Range, Text } from '@/shared';
import { getGamblingHistory, getUserData, setGamblingResults, userState } from '@/features';
import { GamblingApiService } from '@/entities';
import s from './chance.module.scss';
export const ChanceInner = () => {
    const [chanceForm, setChanceForm] = useState({
        amount: '50',
        chance: 3,
    });
    const { user } = useUnit(userState);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [isSending, setIsSending] = useState(false);
    const rawAddress = useTonAddress();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amount') {
            let inputValue = value.replace(/,/g, '.');
            inputValue = inputValue.replace(/^0+(?!$|\.)/, '');
            inputValue = inputValue.replace(/[^0-9.]+/, '');
            inputValue = inputValue.length === 0 ? '0' : inputValue;
            const isValidInput = /^-?\d*\.?\d*$/.test(inputValue);
            if (isValidInput) {
                setChanceForm({ ...chanceForm, amount: value });
            }
        }
        setChanceForm({ ...chanceForm, [name]: value });
    };
    const [canGet, setCanGet] = useState(0);
    useEffect(() => {
        const coef = +(97 / chanceForm.chance).toFixed(2);
        const total = +(Number(chanceForm.amount) * coef - Number(chanceForm.amount)).toFixed(2);
        setCanGet(Number(chanceForm.amount) + total);
    }, [chanceForm]);
    const gamblingApiService = new GamblingApiService();
    const handleSubmit = async () => {
        try {
            setIsSending(true);
            const res = await gamblingApiService.createGame(Number(chanceForm.chance), Number(chanceForm.amount));
            setGamblingResults(res);
            getUserData({ shouldSetLoading: false });
            getGamblingHistory();
            setIsSending(false);
        }
        catch (err) {
            console.error(err);
            toast(`Something went wrong`, {
                type: 'error',
                autoClose: 1800,
                draggable: true,
                bodyStyle: { background: '#E51D41' },
                style: { background: '#E51D41' },
            });
            setIsSending(false);
        }
    };
    return (_jsxs("div", { className: s.inner, children: [_jsx(Input, { className: s.input, placeholder: "ENTER AMOUNT OF PRIVATE", value: chanceForm.amount, setValue: onChange, name: "amount", inputMode: "decimal", pattern: "[0-9]*[.,]?[0-9]*" }), _jsx(Text, { className: s.text, children: "Pick a probability" }), _jsx(Range, { min: 3, max: 95, step: 1, value: chanceForm.chance, onChange: onChange, name: "chance" }), _jsxs("div", { className: s.innerDrop, children: [_jsx(Text, { children: "You can get:" }), _jsxs(Text, { children: [!canGet || canGet === Infinity
                                ? 0
                                : (canGet ?? 0) % 1 === 0
                                    ? canGet.toLocaleString('en', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })
                                    : canGet.toLocaleString('en', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }), ' ', "private"] })] }), _jsx(Button, { variant: BtnVariant.green, className: s.amountBtn, disabled: !rawAddress
                    ? false
                    : Number(chanceForm?.amount) > Number(user?.balance) ||
                        chanceForm.amount === '0' ||
                        chanceForm.amount === '' ||
                        Number(chanceForm.amount) < 50 ||
                        isNaN(Number(chanceForm.amount)), onClick: () => (rawAddress ? handleSubmit() : tonConnectUI.openModal()), isLoading: isSending, children: rawAddress
                    ? Number(chanceForm?.amount) > Number(user?.balance)
                        ? 'Not enough balance'
                        : Number(chanceForm.amount) < 50
                            ? 'Minimum 50'
                            : 'roll'
                    : 'connect' })] }));
};
