// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.juPQnO_4zeD5hmBXpuGY{border:4px solid rgba(255,255,255,.3);border-radius:50%;border-top:4px solid #02713b !important;width:40px;height:40px;animation:cRSpRqqpV1a6S7_8KgWC 1s linear infinite;position:relative;z-index:5}.kfDaV8kZ_iMMWPsS7fWh{width:20px;height:20px;border-width:2px}.G5qwPuirQbRDYdmWj10Q{width:40px;height:40px;border-width:4px}.Z23DsVzcrnUmTWG_GRI1{width:60px;height:60px;border-width:6px}@keyframes cRSpRqqpV1a6S7_8KgWC{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/shared/components/spinner/spinner.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CACA,iBAAA,CACA,uCAAA,CACA,UAAA,CACA,WAAA,CACA,iDAAA,CACA,iBAAA,CACA,SAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,gCACE,GAAA,sBAAA,CACA,KAAA,wBAAA,CAAA","sourcesContent":[".spinner {\n  border: 4px solid rgba(255, 255, 255, 0.3);\n  border-radius: 50%;\n  border-top: 4px solid #02713b !important;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n  position: relative;\n  z-index: 5;\n}\n\n.small {\n  width: 20px;\n  height: 20px;\n  border-width: 2px;\n}\n\n.medium {\n  width: 40px;\n  height: 40px;\n  border-width: 4px;\n}\n\n.large {\n  width: 60px;\n  height: 60px;\n  border-width: 6px;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `juPQnO_4zeD5hmBXpuGY`,
	"spin": `cRSpRqqpV1a6S7_8KgWC`,
	"small": `kfDaV8kZ_iMMWPsS7fWh`,
	"medium": `G5qwPuirQbRDYdmWj10Q`,
	"large": `Z23DsVzcrnUmTWG_GRI1`
};
export default ___CSS_LOADER_EXPORT___;
