// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JlZKZTNYho3eReVhnENY{font-size:18px;font-weight:800;text-transform:uppercase;margin-bottom:16px}.F3097bk67yJf39aUgxoO{font-size:21px;font-weight:800;text-align:center;display:flex;align-items:center;justify-content:center;height:70px}`, "",{"version":3,"sources":["webpack://./src/widgets/stake/ui/stake-list/stake-list.module.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CACA,eAAA,CACA,wBAAA,CACA,kBAAA,CAIJ,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".list { \n  &Title {\n    font-size: 18px;\n    font-weight: 800;\n    text-transform: uppercase;\n    margin-bottom: 16px;\n  }\n}\n\n.listEmpty {\n  font-size: 21px;\n  font-weight: 800;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTitle": `JlZKZTNYho3eReVhnENY`,
	"listEmpty": `F3097bk67yJf39aUgxoO`
};
export default ___CSS_LOADER_EXPORT___;
