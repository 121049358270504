/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createEvent, createStore, combine } from 'effector';
import { UserApiService } from '@/entities';
export const setReferralsList = createEvent();
export const setIsLoadingReferrals = createEvent();
export const getReferralsList = createEvent();
export const referralsStore = createStore(null)
    .on(setReferralsList, (_, payload) => payload);
export const isLoadingStoreReferrals = createStore(false)
    .on(setIsLoadingReferrals, (_, payload) => payload);
const referralsApiService = new UserApiService();
getReferralsList.watch(async () => {
    try {
        setIsLoadingReferrals(true);
        const res = await referralsApiService.getReferrals();
        setReferralsList(res);
    }
    catch (err) {
        console.error('Error fetching friends:', err);
        setReferralsList([]);
    }
    finally {
        setIsLoadingReferrals(false);
    }
});
export const referralsState = combine({ referralsList: referralsStore, referralsLoading: isLoadingStoreReferrals });
