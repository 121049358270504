// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vpt823yb4qv85COR7Ef4{height:100vh;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/shared/components/loadingScreen/loadingScreen.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".inner {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": `Vpt823yb4qv85COR7Ef4`
};
export default ___CSS_LOADER_EXPORT___;
