import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { SvgSelector } from '@/shared';
import s from './countdownTimer.module.scss';
export const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    function calculateTimeLeft() {
        const now = moment().tz('Europe/Moscow');
        const targetMidnight = moment(now).endOf('day').tz('Europe/Moscow');
        let timeDiff = targetMidnight.diff(now);
        if (timeDiff < 0) {
            targetMidnight.add(1, 'day');
            timeDiff = targetMidnight.diff(now);
        }
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60).toString().padStart(2, '0');
        const seconds = Math.floor((timeDiff / 1000) % 60).toString().padStart(2, '0');
        return { hours, minutes, seconds };
    }
    return (_jsxs("div", { className: s.innerInfoTime, children: [_jsx(SvgSelector, { id: "timer" }), "Update: ", timeLeft.hours, ":", timeLeft.minutes, ":", timeLeft.seconds] }));
};
