import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const SvgSelector = ({ id }) => {
    switch (id) {
        case 'plus':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 4C12.5523 4 13 4.44772 13 5V11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L11 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z", fill: "white" }) }));
        case 'arrow-right':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 12C4 11.4299 4.43088 10.9677 4.96241 10.9677L16.7141 10.9677L11.8608 5.76217C11.485 5.35905 11.485 4.70546 11.8608 4.30234C12.2367 3.89922 12.846 3.89922 13.2219 4.30234L19.7181 11.2701C20.094 11.6732 20.094 12.3268 19.7181 12.7299L13.2219 19.6977C12.846 20.1008 12.2367 20.1008 11.8608 19.6977C11.485 19.2945 11.485 18.6409 11.8608 18.2378L16.7141 13.0323H4.96241C4.43088 13.0323 4 12.5701 4 12Z", fill: "#D9DDDB" }) }));
        case 'copy':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.36364 4C7.36364 3.44772 7.81135 3 8.36364 3L20 3C20.5523 3 21 3.44772 21 4V15.6364C21 16.1886 20.5523 16.6364 20 16.6364H16.6364V20C16.6364 20.5523 16.1886 21 15.6364 21H4C3.44772 21 3 20.5523 3 20L3 8.36364C3 7.81135 3.44772 7.36364 4 7.36364L7.36364 7.36364L7.36364 4ZM5 9.36364L5 19H14.6364L14.6364 9.36364L5 9.36364ZM16.6364 14.6364V8.36364C16.6364 7.81135 16.1886 7.36364 15.6364 7.36364L9.36364 7.36364V5L19 5V14.6364H16.6364Z", fill: "#D9DDDB", fillOpacity: "0.6" }) }));
        case 'completed':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.6766 6.27985C20.0833 6.6535 20.11 7.2861 19.7364 7.6928L10.5489 17.6928C10.3651 17.8928 10.1079 18.0095 9.8363 18.016C9.56474 18.0224 9.30223 17.9181 9.10914 17.7271L4.29664 12.9652C3.90406 12.5767 3.90071 11.9436 4.28917 11.551C4.67762 11.1584 5.31078 11.155 5.70336 11.5435L9.7782 15.5755L18.2636 6.33968C18.6373 5.93299 19.2699 5.9062 19.6766 6.27985Z", fill: "#29A468" }) }));
        case 'arrow-right-small':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.66663 8.49999C2.66663 8.11992 2.95388 7.81182 3.30823 7.81182L11.1427 7.81182L7.90718 4.34144C7.65662 4.07269 7.65662 3.63697 7.90718 3.36822C8.15774 3.09947 8.56398 3.09947 8.81454 3.36822L13.1454 8.01338C13.3959 8.28213 13.3959 8.71785 13.1454 8.9866L8.81454 13.6318C8.56398 13.9005 8.15774 13.9005 7.90718 13.6318C7.65662 13.363 7.65662 12.9273 7.90718 12.6585L11.1427 9.18816H3.30823C2.95388 9.18816 2.66663 8.88006 2.66663 8.49999Z", fill: "#D9DDDB", fillOpacity: "0.6" }) }));
        case 'checked':
            return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", children: [_jsx("circle", { cx: "11", cy: "11", r: "11", fill: "#29A468" }), _jsx("path", { d: "M6 11.5L9.52941 15L16 7", stroke: "white", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" })] }));
        case 'timer':
            return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", children: _jsx("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M5.25001 5.25V3H12.75V5.22236L8.99804 8.06102L5.25001 5.25ZM5.06251 1.5C4.71442 1.5 4.38058 1.63828 4.13444 1.88442C3.8883 2.13056 3.75001 2.4644 3.75001 2.8125L3.75 5.34375L3.75003 5.34837C3.75127 5.55047 3.79893 5.74959 3.88931 5.93035C3.97969 6.11112 4.11038 6.26872 4.27132 6.39098L7.75001 8.99999L4.27499 11.6062L4.27131 11.609C4.11038 11.7313 3.97968 11.8889 3.8893 12.0696C3.79891 12.2504 3.75125 12.4495 3.75 12.6516V15.1875C3.75 15.5356 3.88828 15.8694 4.13442 16.1156C4.38056 16.3617 4.7144 16.5 5.0625 16.5H12.9375C13.2856 16.5 13.6194 16.3617 13.8656 16.1156C14.1117 15.8694 14.25 15.5356 14.25 15.1875L14.25 12.6844L14.25 12.6798C14.2487 12.4777 14.2011 12.2785 14.1107 12.0978C14.0203 11.917 13.8896 11.7594 13.7287 11.6372L10.2431 9.00001L13.7275 6.36373L13.7287 6.36284C13.8896 6.24058 14.0203 6.08299 14.1107 5.90223C14.2011 5.72146 14.2488 5.52234 14.25 5.32025V2.8125C14.25 2.4644 14.1117 2.13056 13.8656 1.88442C13.6195 1.63828 13.2856 1.5 12.9375 1.5H5.06251ZM8.99803 9.93898L5.25 12.75V15H12.75V12.7776L8.99803 9.93898Z", fill: "#D9DDDB", "fill-opacity": "0.6" }) }));
        default:
            return null;
    }
};
