// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CDzNDwAYLAW0oSXmYRIc{color:#d9dddb;font-family:"Rogan-Bold";font-size:16px;font-style:normal;text-transform:none;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/shared/components/text/text.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,mBAAA,CACA,eAAA","sourcesContent":[".text {\n  color: #D9DDDB;\n  font-family: 'Rogan-Bold';\n  font-size: 16px;\n  font-style: normal;\n  text-transform: none;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `CDzNDwAYLAW0oSXmYRIc`
};
export default ___CSS_LOADER_EXPORT___;
