import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { v1 } from 'uuid';
import { useUnit } from 'effector-react';
import { gamblingState } from '@/features';
import { ChanceHistoryItem } from '../chance-history-item';
import s from './chance-history.module.scss';
export const ChanceHistory = ({}) => {
    const { gamblingHistory } = useUnit(gamblingState);
    return (_jsxs("div", { className: s.inner, children: [_jsx("h4", { className: s.title, children: "History" }), _jsx("ul", { children: gamblingHistory?.length === 0 ? (_jsx("div", { className: s.emptyHistory, children: "You didn't make a roll yet" })) : (gamblingHistory?.slice(0, 100)?.map(el => _jsx(ChanceHistoryItem, { ...el }, v1()))) })] }));
};
