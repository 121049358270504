import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-internal-modules */
import { useUnit } from 'effector-react';
import { stakesState } from '@/features/stake';
import { BtnVariant, Button, ContentBlock } from '@/shared';
import Coin from '@/shared/lib/images/introduction/coin2.png';
import s from './stake-stats.module.scss';
export const StakeStats = ({ setIsCreating }) => {
    const { userStakes } = useUnit(stakesState);
    const inStakesTotal = userStakes?.filter(el => el.status !== 'END').reduce((acc, el) => acc += el.amount, 0);
    const rewardTotal = userStakes?.filter(el => el.status !== 'END').reduce((acc, el) => acc += el.profit ?? 0, 0);
    return (_jsxs("div", { className: s.inner, children: [_jsxs("h1", { className: s.title, children: ["staking ", _jsx("br", {}), " program"] }), _jsx(ContentBlock, { className: s.content, children: _jsxs("div", { className: s.contentInner, children: [_jsx("img", { src: Coin, alt: "coin" }), _jsxs("div", { className: s.stats, children: [_jsxs("div", { className: s.statsItem, children: [_jsx("p", { className: s.statsItemTitle, children: "in staking" }), _jsxs("p", { className: s.statsItemAmount, children: [_jsx("span", { children: inStakesTotal ?? 0 }), "Private"] })] }), _jsxs("div", { className: s.statsItem, children: [_jsx("p", { className: s.statsItemTitle, children: "rewards" }), _jsxs("p", { className: s.statsItemAmount, children: [_jsx("span", { children: rewardTotal?.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }), "Private"] })] })] }), _jsx("p", { className: s.description, children: "Participate in PRIVATE token staking and earn up to N% APR" }), _jsx(Button, { className: s.btn, onClick: () => setIsCreating(true), variant: BtnVariant.green, children: "Stake Private" })] }) })] }));
};
