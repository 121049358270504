import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useBackButton, useInitData, useMainButton } from '@tma.js/sdk-react';
import { useTonAddress, useTonConnectUI } from 'delab-tonconnect-ui-react';
import { useUnit } from 'effector-react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LoadingScreen, MainLayout } from '@/shared';
import { getPrivateBalance, getUserData, userState, tasksState, setGamblingResults, gamblingState, } from '../features';
const Home = lazy(() => import('./home'));
const Introduction = lazy(() => import('./introduction'));
const Balance = lazy(() => import('./balance'));
const SomethingWentWrong = lazy(() => import('./something-went-wrong'));
const Referrals = lazy(() => import('./referrals'));
const Staking = lazy(() => import('./staking'));
const Gambling = lazy(() => import('./gambling'));
export const Routing = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showIntroduction, setShowIntroduction] = useState(true);
    const { isLoading } = useUnit(userState);
    const { tasksLoading } = useUnit(tasksState);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    setOptions({
        walletsListConfiguration: {
            includeWallets: [
                {
                    appName: 'dewallet',
                    name: 'DeWallet',
                    imageUrl: 'https://avatars.githubusercontent.com/u/116884789?s=200&v=4',
                    aboutUrl: 'https://wallet.tg/',
                    universalLink: 'https://t.me/delabtonbot/wallet?attach=wallet',
                    bridgeUrl: 'https://sse-bridge.delab.team/bridge',
                    platforms: ['ios', 'android', 'macos', 'windows', 'linux'],
                },
            ],
        },
    });
    const navigate = useNavigate();
    const location = useLocation();
    const isTgCheck = useInitData();
    const mainButton = useMainButton();
    const backButton = useBackButton();
    const rawAddress = useTonAddress();
    useEffect(() => {
        if (rawAddress) {
            getPrivateBalance(rawAddress);
        }
    }, [rawAddress]);
    useEffect(() => {
        mainButton.hide();
        backButton.hide();
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.enableClosingConfirmation();
        window.Telegram.WebApp.expand();
        if (isTgCheck?.user) {
            const search = window.location.search;
            const searchParams = new URLSearchParams(search);
            const id = searchParams.get('tgWebAppStartParam');
            const refId = id?.startsWith('ref_') ? id?.slice('ref_'.length) : undefined;
            getUserData({ id: refId, shouldSetLoading: true, rawAddress });
        }
        else {
            navigate('/something-went-wrong');
        }
    }, []);
    const handleBack = () => {
        navigate('/');
        backButton.hide();
    };
    const { gamblingResult } = useUnit(gamblingState);
    useEffect(() => {
        if (location.pathname === '/staking')
            return;
        if (gamblingResult) {
            window.Telegram.WebApp.BackButton.onClick(() => setGamblingResults(null));
            return;
        }
        if (location.pathname === '/' || location.pathname === '/introduction') {
            backButton.hide();
        }
        else {
            backButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBack);
        }
        return () => window.Telegram.WebApp.BackButton.offClick(handleBack);
    }, [location, gamblingResult]);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (location.pathname !== '/introduction') {
            document.body.style.overflowY = 'auto';
        }
    }, [location]);
    useEffect(() => {
        const hasPassedIntroduction = localStorage.getItem('hasPassedIntroductionn');
        if (hasPassedIntroduction) {
            setShowIntroduction(false);
        }
        else {
            navigate('/introduction');
        }
    }, []);
    // useEffect(() => {
    //     let intervalId: any;
    //     if (location.pathname !== '/introduction') {
    //         clearInterval(intervalId);
    //         return;
    //     }
    //     intervalId = setInterval(() => {
    //         console.log('check subscribes');
    //         getTasksStatusLite();
    //     }, 10000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [location.pathname]);
    return (_jsx(_Fragment, { children: isLoading || tasksLoading ? (_jsx(LoadingScreen, {})) : (_jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/introduction", element: _jsx(Introduction, {}) }), _jsx(Route, { path: "/balance", element: _jsx(Balance, { rawAddress: rawAddress }) }), _jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/referrals", element: _jsx(Referrals, {}) }), _jsx(Route, { path: "/staking", element: _jsx(Staking, {}) }), _jsx(Route, { path: "/gambling", element: _jsx(Gambling, {}) }), _jsx(Route, { path: "/something-went-wrong", element: _jsx(SomethingWentWrong, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/" }) })] }) }) })) }));
};
