// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yw49l_PksiNl2mKFeRrm{display:flex;align-items:center;gap:5px;color:rgba(217,221,219,.6);font-family:"Rogan-Bold";font-size:16px;font-weight:600}`, "",{"version":3,"sources":["webpack://./src/shared/components/countdownTimer/countdownTimer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,0BAAA,CACA,wBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".innerInfoTime {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  color: rgba(217, 221, 219, 0.60);\n  font-family: 'Rogan-Bold';\n  font-size: 16px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerInfoTime": `yw49l_PksiNl2mKFeRrm`
};
export default ___CSS_LOADER_EXPORT___;
