/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
import { ApiService } from '@/shared/utils/api-service';
const apiUrl = 'https://starfish-app-i3bq6.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class TaskService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    // ПОЛУЧЕНИЕ ВСЕХ ТАСОК НА ПОДПИСКУ
    async checkUserSubscribes() {
        return this.apiService.get('task', {});
    }
}
