import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Button, Modal } from '@/shared';
import { setGamblingResults } from '@/features';
import s from './chance-result.module.scss';
import LOSE from '@/shared/lib/images/result/lose.png';
import WIN from '@/shared/lib/images/result/win.png';
export const ChanceResult = ({ isResult }) => {
    const isLose = String(isResult?.result).startsWith('-');
    const resultText = !isLose ? "Success! You've won" : 'You can try it another time.';
    return (_jsx(Modal, { isOpen: !!(isResult?.result), children: _jsxs("div", { className: s.inner, children: [_jsxs("div", { className: s.innerContent, children: [_jsxs("div", { className: s.innerImg, children: [_jsx("img", { src: isLose ? LOSE : WIN, alt: "preview" }), _jsx("div", { className: classNames(s.innerDecor, !isLose ? s.innerDecorWin : s.innerDecorLose) })] }), _jsxs("h5", { className: s.innerContentTitle, style: { color: !isLose ? '#10B981' : '#FF1C45' }, children: [isLose ? 'LOSS' : 'WIN', " ", !isLose && _jsx("span", { children: `(${isResult?.result} PRIVATE)` })] }), _jsx("p", { children: resultText })] }), _jsx(Button, { onClick: () => setGamblingResults(null), className: s.btn, children: "Continue" })] }) }));
};
