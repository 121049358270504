/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-debugger */
/* eslint-disable import/no-extraneous-dependencies */
import { fromNano } from '@ton/core';
import { createEvent, createStore, combine } from 'effector';
import { getReferralsList, getDepositHistory, getWithdrawHistory, getTasksStatus, getGamblingHistory } from '@/features';
import { UserApiService, TonApiService } from '@/entities';
import { getAvailableStakes, getUserStakes } from '../stake';
export const setUser = createEvent();
export const userStore = createStore(null)
    .on(setUser, (_, newUser) => newUser);
export const privateBalanceStore = createStore('0');
export const isErrorStore = createStore(false);
export const setIsError = createEvent();
isErrorStore.on(setIsError, (_, isError) => isError);
export const isLoadingStoreUser = createStore(false);
export const setIsLoadingUser = createEvent();
export const setPrivateBalance = createEvent();
privateBalanceStore.on(setPrivateBalance, (_, privateBalance) => privateBalance);
isLoadingStoreUser.on(setIsLoadingUser, (_, isLoading) => isLoading);
export const getUserData = createEvent();
export const getPrivateBalance = createEvent();
const userApiService = new UserApiService();
const tonApi = new TonApiService();
getPrivateBalance.watch(async (rawAddress) => {
    try {
        const tonApiRes = await tonApi.getAllJettonsBalances(rawAddress);
        const privatePrice = tonApiRes?.balances.find((el) => el.jetton?.address === '0:37042dbb5cd7a29e5405bef4108a9304a132eea54b210244956f45b5dbd4c7fc');
        setPrivateBalance(fromNano(privatePrice?.balance ?? '0'));
    }
    catch (error) {
        console.error(error);
    }
});
getUserData.watch(async ({ id, shouldSetLoading = true }) => {
    try {
        if (shouldSetLoading) {
            setIsLoadingUser(true);
        }
        const res = await userApiService.getUser(id);
        if (!res) {
            setIsError(true);
        }
        else {
            setIsError(false);
            setUser(res);
        }
    }
    catch (error) {
        console.error('Error initializing user:', error);
        setIsError(true);
    }
    finally {
        if (shouldSetLoading) {
            getReferralsList();
            getDepositHistory();
            getWithdrawHistory();
            getTasksStatus();
            getUserStakes();
            getAvailableStakes();
            getGamblingHistory();
            setIsLoadingUser(false);
        }
    }
});
export const userState = combine({
    user: userStore,
    isError: isErrorStore,
    isLoading: isLoadingStoreUser,
    privateBalance: privateBalanceStore
});
