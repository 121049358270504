/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
import { ApiService } from '@/shared';
const apiUrl = 'https://starfish-app-i3bq6.ondigitalocean.app/';
const headers = { 'telegram-data': window.Telegram.WebApp.initData };
export class DepositApiService {
    constructor() {
        this.apiService = new ApiService(apiUrl, headers);
    }
    // ПОЛУЧЕНИЕ ИСТОРИИ ДЕПОЗИТОВ
    async depositsHistory() {
        return this.apiService.get('deposit/history', {});
    }
}
