import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import s from './refItem.module.scss';
export const RefItem = ({ username, isSubscribed }) => (_jsxs("div", { className: s.item, children: [_jsxs("div", { className: s.itemAvatar, children: [_jsx("img", { src: `https://t.me/i/userpic/320/${username}.jpg`, onLoad: (e) => {
                        const target = e.currentTarget;
                        if (target.naturalWidth === 0 || target.naturalHeight === 0) {
                            target.style.display = 'none';
                        }
                    }, alt: "" }), _jsx("div", { style: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }, children: username?.slice(0, 2)?.toUpperCase() || 'EL' })] }), _jsxs("div", { className: s.inner, children: [_jsx("div", { className: s.initials, children: _jsx("p", { className: s.name, children: username?.length > 9 ? username?.slice(0, 9) + '...' : username }) }), _jsxs("p", { className: s.profit, children: [isSubscribed ? '10' : '0', " PRIVATE"] })] })] }));
