import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import { Spinner } from '../spinner';
import s from './button.module.scss';
// Wrap external link to <a> and internal link to router's <NavLink>
const LinkWrapper = ({ children, link, className, forwardProps, style }) => {
    const isLink = !!link;
    const isExternalLink = !link?.startsWith('/');
    if (isLink) {
        if (isExternalLink) {
            return (_jsx("a", { href: link, className: className, target: "_blank", ...forwardProps, style: style, children: children }));
        }
        else {
            return (_jsx(NavLink, { to: link, className: className, style: style, ...forwardProps, children: children }));
        }
    }
    else {
        return (_jsx("div", { className: className, style: style, ...forwardProps, children: children }));
    }
};
export default LinkWrapper;
export const ButtonWrapper = ({ children, link }) => {
    const isLink = !!link;
    return isLink ? _jsx(LinkWrapper, { link: link, children: children }) : _jsx("div", { children: children });
};
export const BtnVariant = {
    green: 'greenVariant',
    grey: 'greyVariant',
    transparent: 'transparentVariant'
};
export const Button = ({ ref, children, disabled = false, variant = BtnVariant.grey, link, onClick, className, isLoading, smallButton = false }) => (_jsx(ButtonWrapper, { link: link, children: _jsxs("button", { disabled: disabled, className: `${s.button} ${className || ''} ${s[variant]} ${smallButton ? s.buttonSmall : ''}`, onClick: onClick, ref: ref, children: [_jsx("span", { className: s.buttonText, children: children }), isLoading && _jsx(Spinner, { size: 'small' })] }) }));
