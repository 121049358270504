/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
export class ApiService {
    constructor(url, headers) {
        this._url = url;
        this._headers = headers;
    }
    handleError(error) {
        console.error(error);
        return undefined;
    }
    async makeGetRequest(url, data) {
        try {
            const config = { params: data, headers: this._headers };
            const res = await axios.get(`${this._url}${url}`, config);
            return res.data;
        }
        catch (error) {
            return this.handleError(error);
        }
    }
    async makePostRequest(url, data) {
        try {
            const res = await axios.post(`${this._url}${url}`, data, { headers: this._headers });
            return res.data;
        }
        catch (error) {
            return this.handleError(error);
        }
    }
    async get(url, data) {
        return this.makeGetRequest(url, data);
    }
    async post(url, data) {
        return this.makePostRequest(url, data);
    }
}
